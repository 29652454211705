@import "_variables.scss";
@import "_fonts.scss";

@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "~bootstrap/scss/bootstrap";
@import "~video.js/src/css/vjs";

@import "_typography.scss";
@import "_lib.scss";
@import "_main.scss";
@import "_block.scss";

@import "pages/_home.scss";
@import "pages/_product.scss";
@import "pages/_contact.scss";


