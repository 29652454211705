.contact-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    .contact-list {
        padding: 10px 20px 0 0;
    }
    .contact-list-half {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    small {
        opacity: 0.8;
    }

    @media screen and (max-width: 576px) {
        grid-template-columns: 1fr;
        gap: 20px;

        .contact-list {
            padding: 0;
        }
    }
    .contact-map {
        img {
            width: 100%;
        }
    }
}
