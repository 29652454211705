/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 29, 2020 */



@font-face {
    font-family: 'WF Sarabun';
    src: url('sarabun-regular-webfont.woff2') format('woff2'),
         url('sarabun-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'WF Sarabun';
    src: url('sarabun-italic-webfont.woff2') format('woff2'),
         url('sarabun-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'WF Sarabun';
    src: url('sarabun-semibold-webfont.woff2') format('woff2'),
         url('sarabun-semibold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'WF Sarabun';
    src: url('sarabun-semibolditalic-webfont.woff2') format('woff2'),
         url('sarabun-semibolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}
