.products-list-categories {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    .products-list-category {
        background: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,.3);
        .products-list-category-content {
            padding: 5px 15px 0;
        }
    }
}

.products-list {
    list-style: none;
    display: grid;
    padding: 0;
    margin: 10px 0;
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    text-align: center;
    .products-list-item {
        background: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        transition: all 0.3s;

        a {
            text-decoration: none;
        }

        .content-list-item-content {
            display: block;
            padding: 5px;
        }
    }
}
