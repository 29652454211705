.header-slideshow {
    margin: 0 0 50px 0;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 800px) {
        display: none;
    }

    .slick-dots {
        li {
            background: #fff;
        }
    }
}
.header-slideshow-mobile {
    display: none;
    margin: 0 0 30px 0;
    @media screen and (max-width: 800px) {
        display: block;
        .slick-dots {
            bottom: -10px;
        }
    }
}
.crafty-block-slideshow-slide {
    position: relative;
    .crafty-block-slideshow-bg {
        &> img {
            width: 100%;
        }
    }
    .crafty-block-slideshow-content {
        max-width: 1000px;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        &>div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: left;
            align-items: center;
        }
        img {
            max-width: 60%;
            max-height: 100%;
        }
    }
}
.slick-slide {
    .crafty-block-slideshow-content {
        opacity: 0;
        transition: all 0.6s;
    }
}
.slick-slide:nth-child(1) {
    .crafty-block-slideshow-content {
        &>div {
            justify-content: left;
        }
    }
}
.slick-slide:nth-child(2) {
    .crafty-block-slideshow-content {
        &>div {
            justify-content: center;
        }
    }
}
.slick-active {
    .crafty-block-slideshow-content {
        opacity: 1;
        transform: translateY(0);
        animation-name: slide-content;
        animation-duration: 1s;
    }
}
@keyframes slide-content {
    0%   {opacity: 0;transform: translateY(60%);}
    50%   {opacity: 0;transform: translateY(60%);}
    90%   {opacity: 1;transform: translateY(-30px);}
    100% {opacity: 1;transform: translateY(0);}
}

.home-contact-section {
    margin: 50px 0;
    padding: 30px 0 35px;
    background: #eee;

    .contact-social-buttons {
        display: grid;
        justify-content: center;
        align-items: center;
        gap: 10px;
        grid-template-columns: repeat(4,1fr);
        .contact-btn {

        }
        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 576px) {
            grid-template-columns: repeat(1,1fr);
        }
    }

}

.home-services-section {
    margin: 50px 0;
    padding: 40px 0;
    background: #eee;
    .crafty-block-list {
        display: grid;
        grid-template-columns: repeat(4,1fr);
        gap: 30px;
        text-align: center;
        margin: 40px 0 0;

        @media screen and (max-width: 768px) {
            grid-template-columns: repeat(2,1fr);
            gap: 20px;
        }
        @media screen and (max-width: 576px) {
            gap: 10px;
            grid-template-columns: repeat(2,1fr);
        }

        .crafty-block-list-image {
            width: 90px;
            height: 80px;
            margin: 0 auto 10px;
            display: flex;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

.home-products {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
    margin: 20px 0 40px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2,1fr);
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1,1fr);
    }

    .home-product-item {
        background: #fff;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
        padding: 0 0 5px;
        transition: all 0.3s;
        &:hover {
            box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
        }
        img {
            width: 100%;
        }
        strong {
            display: block;
            padding: 5px 10px;
            line-height: 1.2em;
        }
        span {
            display: block;
            padding: 3px 10px;
            font-size: 0.9em;
            line-height: 1.5em;
            height: 1.5em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        a {
            text-decoration: none;
        }
    }
}

.page-hero-content {
    max-width: 960px;
    margin: 0 auto;
    font-size: 1.2em;
    line-height: 1.5em;
}

.home-brands {
    text-align: center;
    .home-brand-item {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 80%;
            max-height: 90%;
        }
    }
}
