html {
    height: 100%;
}
body.app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
}
table p {
    margin: 0 0 5px;
}
#topmost {
    background: darken($color-primary, 10);
    font-size: 0.9em;
    .content-container {
        display: flex;
        justify-content: space-between;
    }
    a {
        color: #fff;
    }

    #lang-nav {
        display: flex;
        font-size: 0.9em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        a {
            display: block;
            text-transform: uppercase;
            padding: 0 10px;
            position: relative;
            opacity: 0.7;
            font-weight: bold;
            &.active {
                opacity: 1;
            }
            &:after {
                line-height: 24px;
                content: "";
                display: inline-block;
                margin: 0;
                width: 1px;
                height: 12px;
                background: #fff;
                opacity: 1;
                position: absolute;
                top: 6px;
                right: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            &:last-child:after {
                display: none;
            }
        }
    }
}
#main-header {
    background: $color-primary;
    position: relative;
    z-index: 2;
}
#main-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    @media screen and (max-width: 690px) {
        flex-direction: column;
    }
    @media screen and (max-width: 576px) {
        flex-direction: column;
    }
}
#main-logo {
    width: 150px;
    @media screen and (max-width: 768px) {
        width: 120px;
    }
    @media screen and (max-width: 576px) {
        width: 100px;
        margin: -5px 0 0;
    }
}
#header-contact {
    .crafty-block-list {
        display: flex;
    }
    .crafty-block-list-item {
        position: relative;
        padding: 0 20px 0 35px;
        border-right: 1px solid #ddd;
        margin: 0 0 0 20px;
        @media screen and (max-width: 576px) {
            padding-left: 0;
            padding: 0 10px;
            flex: 1;
            margin: 0;
        }
        .crafty-block-list-image {
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -15px;
            img {
                height: 20px;
            }
            @media screen and (max-width: 576px) {
                display: none;
            }
        }
        .crafty-block-list-title {
            font-size: 0.8em;
            line-height: 1.1em;
            color: #808080;
            margin: 0;
            padding: 0;
        }
        .crafty-block-list-description {
            margin: 0;
            padding: 0;
            white-space: nowrap;
        }
        &:last-child {
            padding-right: 0;
            border-right: none;
        }
        @media screen and (max-width: 768px) {
            font-size: 0.9rem;
        }
    }
}
#main-nav {
    text-align: right;
    color:  #fff;

    a {
        font-size: 1.2em;
        color: #fff;
        font-weight: bolder;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
#main-nav {
    flex:1;
    margin: 0;
	text-align:left;
    position: relative;
    font-size: 0;
    font-family: $font-family-heading;
}
#main-nav ul.menu {
	list-style: none;
	padding: 0;
    margin: 0 0 0 -15px;
    font-weight: 500;
    font-size: 0.9rem;

    @media screen and (max-width: 768px) {
        font-size: 0.9rem;
    }
}
#main-nav ul.menu > li {
    display: inline-block;
}
#main-nav ul.menu > li > a {
	display: block;
	line-height: 40px;
	padding: 0 15px;
    white-space: nowrap;
}
#main-nav ul.menu > li > a:hover {
    text-decoration: none;
    text-decoration: underline;
}

#main-nav ul.menu > li.contact-nav {
    a {
        background: $color-secondary;
        border-radius: 60px;
        padding: 0 30px;
        display: inline-block;
    }
}
.main-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media screen and (min-width: 577px) and (max-width: 900px) {
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    @media screen and (max-width: 690px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.main-nav-subnav {
	overflow: hidden;
	max-height: 0;
	position: absolute;
	top: 100%;
	left: 0;
	font-size: 15px;
	text-align: left;
	line-height: 1.6em;
	text-transform: none;
	margin-top: -5px;
	padding: 0;
	margin: 0;
    transition: max-height 0.4s;
    z-index: 9999;
    width: 280px;
    font-family: $font-family-general;
}
#main-nav .menu > li:hover > .main-nav-subnav {
	max-height: 600px;
}
.main-nav-subnav li {
	display: block;
    clear: both;
    padding: 0;
    margin: 0;
}
.main-nav-subnav a {
    padding: 5px 10px;
    display: block;
    position: relative;
    background: $color-primary;
}
.main-nav-subnav a:hover {
    text-decoration: none;
    background: #000;
}


/** MOBILE NAV **/
#main-nav .mobile-toggle {
	display: none;
	width: 100%;
    font-size: 30px;

    @media screen and (max-width: 576px) {
        font-size: 18px;
    }
}
#main-nav #mobile-toggle {
	display: none;
}

@media screen and (max-width: 576px) {
    #main-nav ul.menu > li.contact-nav {
        a {
            background: transparent;
            padding: 0 16px;
            display: block;
            border-radius: 0;
        }
    }
    .main-nav-subnav,
    .main-nav-down-arrow {
        display: none;
    }
	#main-nav {
        position: absolute;
        top: 5px;
        left: 15px;
        background: transparent;
    }
	#main-nav .mobile-toggle {
		text-align: center;
		color:$color-primary;
		display: block;
		position: relative;
        border-radius: 20px;
		margin: 10px 0 0 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        z-index: 9999;
        background: #fff;
	}
	#main-nav .main-nav-container {
        overflow: hidden;
        position: fixed;
        top: 25px;
        left: -100%;
        height: 100%;
        width: 60%;
        z-index: 9998;
        background: $color-primary;
        transition: left 0.4s ease-in-out;
        align-items: flex-start;

        #site-nav {
            width: 100%;
        }

        ul.menu {
            display: flex;
            flex-direction: column;
            margin: 60px 0 0 0;
            width: 100%;

            li {
                width: 100%;
            }
            a {
                color: #fff;
                display: block;
                width: 100%;
                border-bottom: 1px solid rgba(255,255,255,0.4);
                line-height: 36px;
                padding-left: 16px;
            }
            a:hover,
            a:active {
                color: #fff;
                text-decoration: underline;
            }
        }
	}
	#mobile-toggle:checked ~ .main-nav-container  {
		left:0;
		border-bottom: 1px solid rgba(255,255,255,0.6);
    }
    .mobile-toggle {
        span {
            display: inline;
        }
        span, small {
            font-size: 1em;
        }
        small {
            display: none;
        }
    }
    .mobile-toggle.opening {
        span {
            display: none;
        }
        small {
            display: inline;
        }
    }

    .main-nav-about {
        ul.main-nav-subnav {
            max-height: 600px;
            position: relative;
            top: 0;
            display: block;
            font-size: 0.9em;

            a {
                line-height: 20px !important;
                &:before {
                    content: "-";
                }
            }
        }
    }
}

.content-container {
    max-width:1100px;
    margin:0 auto;
    padding:0 40px;
    width:100%
}
@media screen and (max-width:768px){
    .content-container{
        padding:0 20px
    }
}

.content-section-header-group {
    margin: 0 0 2em;
    h1 {
        font-weight: bold;
        font-size: 1.8em;
        line-height: 1.4em;
    }
    h2 {
        font-weight: normal;
        font-size: 1em;
        font-weight: 600;
    }
}
.secondary-heading {
    .content-section-header-group {
        margin: 0 0 1.5em;
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        h1 {
            color: #222;
            font-size: 1.8em;
            line-height: 1.4em;
            font-weight: bold;
            padding: 0;
            margin: 0;
        }
        h2 {
            color: $gray-color;
            font-size: 1em;
            font-weight: 600;
            padding: 0;
            margin: 0;
            font-weight: normal;
        }
    }
}

#footer-copy {
    color: #fff;
    background: darken($color-primary,10);
    text-align: center;
    font-size: 12px;
    line-height: 2em;
    padding: 3px 0 0 0;
    border: none;
}

#main-footer {

}
#main-footer-content {
    background: $color-primary;
    margin: 80px 0 0;
    border: 1px solid;
    padding: 30px 0;
    position: relative;
    text-align: left;
    border:none;
    color: #fff;
    font-size: 0.9em;
    line-height: 1.5em;
    a {
        color: #fff;
    }

    .footer-contact-content {
        display: grid;
        padding: 0;
        gap: 20px;
        grid-template-columns: 4fr 6fr 3fr;

        @media screen and (max-width: 1024px) {
            grid-template-columns: repeat(2,1fr);
        }
        @media screen and (max-width: 576px) {
            grid-template-columns: repeat(1,1fr);
        }
        .footer-contact-content-header {
            .footer_contact_logo {
                width: 60%;
                margin-bottom: 20px;
            }
        }
        .footer-contact-content-nav {
            flex: 6;
        }
        .footer-contact-content-detail {
            flex: 3;
        }
    }
    .footer-product-categories {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 0 10px;
    }
}
.contact-social-buttons {
    .contact-btn {
        color: #fff;
        position: relative;
        padding: 5px 10px 5px 45px;
        display: block;
        margin: 0 0 10px;
        font-size: 1.1em;
        line-height: 1.2em;
        border-radius: 60px;
        position: relative;
        background: #444;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
        transition: all 0.3s;
        &:hover {
            text-decoration: none;
            box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
        }
        &.contact-btn-fb {
            background: #1877F2;
        }
        &.contact-btn-line {
            background: #00B632;
        }
        &.contact-btn-shopee {
            background: #FB5533;
        }
        small {
            display: block;
            opacity: 0.7;
        }
        img {
            position: absolute;
            top: 10px;
            left: 12px;
            max-width: 26px;
            height: calc( 100% - 20px );
        }
    }
}

.breadcrumb {
    background: $color-pale;
    padding: 0.5em 0 0.4em;
    font-size: 0.8em;
    margin: 0 0 2rem;
}

.btn {
    font-family: $font-family-heading;
    font-weight: bold;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.btn-rounded {
    border-radius: 100px;
}
