/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 26, 2017 */



@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-extralight-webfont.woff2') format('woff2'),
         url('kanit-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-extralightitalic-webfont.woff2') format('woff2'),
         url('kanit-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-medium-webfont.woff2') format('woff2'),
         url('kanit-medium-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'WF Kanit';
    src: url('kanit-mediumitalic-webfont.woff2') format('woff2'),
         url('kanit-mediumitalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}
